<template>
  <div id="designList" class="p-8">
    <div class="flex items-center text-white">
      <h1 class="text-5xl mr-4">My Designs</h1>
      -
      <router-link
        class="
          ml-4
          bg-gray-900
          hover:bg-black
          text-white
          font-bold
          py-2
          px-4
          rounded
        "
        to="/create"
      >
        <button>New Design</button>
      </router-link>
    </div>
    <ul class="flex flex-wrap cards">
      <li v-for="(item, index) in items" :key="`item-${index}`">
        <email-item
          :thumbnail="item?.Metadata?.thumbnail"
          :title="item?.Key?.split('/').pop().split('.')[0]"
          :LastModified="item?.LastModified"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { executeApiCall } from '@/helpers/apiClient';

import EmailItem from '@/components/EmailItem.vue';

export default {
  name: 'designList',
  data() {
    return {
      items: [],
    };
  },
  components: {
    EmailItem,
  },
  mounted() {
    this.getTemplateList();
  },
  methods: {
    async getTemplateList() {
      const path = '/getTemplateList';

      const res = await executeApiCall('get', path, null);
      // res?.data?.items.shift(); // remove first item because this is no file but the parent folder
      this.items = res?.data?.items;
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  background-color: rgb(41, 48, 57);
}

#EmailTempBuilderapp,
#example {
  height: 100%;
}

#example .container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

button {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(14, 19, 24);
  color: #fff;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}
</style>
